@import-normalize;

@import "assets/scss/reset";
@import "assets/scss/modules/Forms";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body, #root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #F2F2F2;
}
