/* Reset */

*{
	outline:0;
	padding:0;
	margin:0;
	border:0;
     -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
             box-sizing:border-box;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.clearfix { display: inline-table; }
* html .clearfix { height: 1%; }
.clearfix { display: block; }
